import { onMounted, onUnmounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';

export default function () {
  const store = useStore();

  function popstate(history) {
    store.dispatch('router/pop', { path: window.getPath(), data: history.state });
  }

  const meta = computed(() => store.state.router.popup?.meta || store.state.router.page?.meta);
  useHead({
    title: computed(() => meta.value?.title),
    meta: [
      { name: 'description', content: computed(() => meta.value?.description) },
      { property: 'og:description', content: computed(() => meta.value?.description) },
      { property: 'og:title', content: computed(() => meta.value?.title) },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'Skuba' },
    ],
  });

  onMounted(async () => {
    try {
      await store.dispatch('router/init', { uri: window.getPath(), hydrate: !!window.INITAL_ROUTER });
    } catch (err) {
      console.error('Server unreachable', err);
    }

    window.addEventListener('popstate', popstate);

    if (window.prerender) {
      // wait end of animations
      setTimeout(() => {
        // store state
        const script = document.createElement('script');
        script.innerHTML = `window.INITAL_ROUTER = ${JSON.stringify(store.state.router)};`;
        document.head.appendChild(script);

        // we're done
        window.document.dispatchEvent(new Event('prerender-trigger'));
      }, 5000);
    }
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', popstate);
  });
}
