<template>
  <div class="accordion" :class="{ expanded: expandedIds.length > 0 }">
    <dropdown
      v-for="item in items"
      :key="item.id"
      :expanded="isExpanded(item.id)"
      :disabled="item.disabled"
      @update:expanded="setExpanded(item.id, $event)"
    >
      <template #header="{ expanded }">
        <slot
          :key="item.id"
          name="header"
          :item="item"
          :expanded="expanded"
        />
      </template>
      <template #default="{ expanded }">
        <slot
          :key="item.id"
          :item="item"
          :expanded="expanded"
        />
      </template>
    </dropdown>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';

export default {
  props: {
    mode: { type: String, default: 'single' },
    items: { type: Array, default: () => [] },
  },

  setup(props) {
    const { mode } = toRefs(props);
    const expandedItems = reactive({});

    const setExpanded = (id, value) => {
      if (value && mode.value === 'single') {
        expandedItems.value = { [id]: true };
      } else {
        expandedItems.value = { ...expandedItems.value, [id]: value };
      }
    };

    const isExpanded = (id) => !!expandedItems.value?.[id];
    const expandedIds = computed(() => Object.keys(
        expandedItems?.value || {},
    ).filter((id) => expandedItems.value[id]));

    return {
      expandedItems,
      setExpanded,
      isExpanded,
      expandedIds,
    };
  },
};
</script>

<style>

</style>
