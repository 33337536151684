<template>
  <Go
    :to="to"
    class="inline-block mt-4 btn"
  >
    <span class="slot"><slot /></span>
  </Go>
</template>

<script>
export default {
  props: {
    to: { type: String, default: null },
  },
};
</script>
