<template>
  <div class="hamburger">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  will-change: transform;
  box-sizing: content-box;
  cursor: pointer;
  width: 55px;
  padding: 10px 5px;
}

.bar {
  @apply rounded-sm;
  @apply bg-black;

  height: 4px;
  width: 45px;
  transition: all 0.5s;

  .hamburger:hover & {
    &:nth-child(2) {
      width: 42px;
    }

    &:nth-child(3) {
      width: 39px;
    }
  }

  .hamburger.active & {
    @apply bg-orange;

    &:nth-child(1) {
      transform: rotate(-45deg) translate(-10px, 5px);
    }

    &:nth-child(2) {
      transform: scaleX(0);
    }

    &:nth-child(3) {
      width: 45px;
      transform: rotate(45deg) translate(-11px, -7px);
    }
  }

  &:nth-child(2),
  &:nth-child(3) {
    margin-top: 8px;
  }
}
</style>
