<template>
  <div id="nav">
    <div class="inner">
      <div class="inner-elem">
        <Hamburger
          :class="{ active: mobile }"
          @click="toggle()"
        />
      </div>

      <div class="inner-elem">
        <Go :to="'/' + $store.state.router.locale" aria-label="Home">
          <img src="@/assets/images/logo.png" class="logo">
        </Go>
      </div>

      <div class="inner-elem">
        <div class="hidden md:block socials">
          <Go
            v-for="(platform, i) in socials"
            :key="'header-' + platform.name"
            :to="platform.link"
          >
            <component :is="platform.name" class="h-4 inline" :class="{ 'mr-4': i < socials.length - 1 }" />
          </Go>
        </div>
      </div>
    </div>
  </div>

  <div class="mobile-wrapper">
    <div class="mobile-nav" :class="{ active: mobile }">
      <div class="mobile-links">
        <Go
          v-for="sub of navigation?.links"
          :key="sub.id"
          :to="sub.uri"
          class="px-4 link"
          :class="{ active: sub.uri === currentUri }"
          @click="toggle(true)"
          v-text="sub.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import Scrolllock from '@/composables/scrolllock';
import Hamburger from '../utils/Hamburger.vue';
import Facebook from '@/assets/images/facebook.svg';
import Twitter from '@/assets/images/twitter.svg';
import Instagram from '@/assets/images/instagram.svg';

export default {
  components: {
    Hamburger,
    Facebook,
    Twitter,
    Instagram,
  },
  setup() {
    const store = useStore();
    const { lock, unlock } = Scrolllock();

    return {
      lock,
      unlock,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    currentUri() {
      return this.$store.state.router.page.meta?.uri;
    },
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    socials() {
      if (this.$store.state.router.layout.footer) {
        return this.$store.state.router.layout.footer.socials;
      }

      return [];
    },
  },
  methods: {
    toggle(close) {
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        // setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        // setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_grid.scss";
@import "@/assets/styles/_global.scss";

.logo {
  width: auto;
  height: calc(var(--header-height) - var(--header-padding) * 2);
}

#nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: var(--header-height);
  padding: var(--header-padding);
  border-bottom: 4px solid black;
  pointer-events: none;
  background-color: theme('colors.white');
}

.inner {
  @apply relative constrain flex items-center text-sm;
  @apply w-full;

  padding-left: calc(var(--content-padding) - var(--header-padding));
  padding-right: calc(var(--content-padding) - var(--header-padding));

  .inner-elem {
    flex: 1;
    display: flex;
    justify-content: center;

    &:first-child > * {
      margin-right: auto;
    }

    &:last-child > * {
      margin-left: auto;
    }
  }
}

.logo,
.hamburger,
.socials {
  pointer-events: all;
}

.link {
  @apply font-serif;
  @apply font-bold;
  @apply text-primary;
  @apply text-dark-blue;

  font-size: px(23);
  line-height: px(29);

  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  #nav &:last-child {
    @extend .btn;
    padding-left: px(30);
    padding-right: px(30);
  }

  &.active {
    @apply text-primary;

    &::after {
      @apply bg-primary;

      content: "";
      position: absolute;
      left: 0;
      top: calc(100% + #{px(4)});
      width: 70%;
      height: 3px;
    }
  }

  #nav &.active:last-child::after {
    content: none;
  }
}

/* MOBILE NAV */
.mobile-nav {
  @apply bg-black;
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  top: var(--header-height);
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
  transform-origin: top center;
  transition: transform var(--speed-fast) ease-out;

  pointer-events: none;

  &.active {
    transform: none;
    pointer-events: all;
  }

  @apply text-white;

  .link {
    display: block;
    text-decoration: none !important;
  }

  .mobile-links {
    @apply text-center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .link {
      @apply font-sans;
      @apply text-white;
      @apply mb-3;

      &.active {
        @apply text-orange;
      }

      &.active::after {
        content: none;
      }
    }
  }
}

.link-line .head {
   @apply flex;
   @apply justify-between;
   @apply items-center;
   @apply text-white;
}

:deep(.accordion.expanded) .head {
  color: rgba(255, 255, 255, 0.6);
}

.link-line .children {
  @apply flex;
  @apply flex-col;
  @apply items-start;
  padding-top: px(3);
  padding-bottom: px(12);

  .link {
    margin-top: px(8);
    margin-bottom: px(8);
    line-height: px(22);
  }
}
:deep(.dropdown):not(:last-child) {
  @apply border-b;
  border-bottom-color: rgba(255, 255, 255, 0.6);
}

:deep(.arrow) {
  .has-children & {
    &.down {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(-90deg);
    }
  }
}
</style>
