import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Arrow from '@/components/utils/Arrow.vue';
import Image from '@/components/utils/Image.vue';
import Dropdown from '@/components/utils/Dropdown.vue';
import Accordion from '@/components/utils/Accordion.vue';

import store from './store';
import checkView from './vue-check-view';

import './assets/styles/main.scss';

// polyfills
// --------------------------------

smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(VueScrollTo);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');
app.config.globalProperties.$tryCDN = (url, width) => {
  if (url && url.includes('backend.jkweb.dev')) {
    const el = document.createElement('a');
    el.href = url;
    return `https://jkweb-dev.b-cdn.net${el.pathname}${width ? `?width=${width}` : ''}`;
  }
  return url;
};

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);
app.component('Arrow', Arrow);
app.component('Accordion', Accordion);
app.component('Dropdown', Dropdown);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
