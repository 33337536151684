<template>
  <footer class="footer border-t-4 border-t-black">
    <div class="constrain grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-y-0 gap-x-8">
      <div class="md:col-span-2">
        <h4>
          skuba - Studentische Körperschaft der Universität Basel
        </h4>
      </div>
      <div class="hidden md:block" />
      <div v-html="payload.textlinks" />
      <div v-html="payload.extratext" />
      <div class="text-right">
        <h4>Netzwerk</h4>
        <Go
          v-for="image in payload.netzwerk"
          :key="image.id"
          :to="image.caption"
        >
          <Image
            :src="image"
            class="object-contain object-right h-8 mt-4"
            :inline="false"
          />
        </Go>
      </div>
    </div>
  </footer>
</template>

<script>
import Image from '../utils/Image.vue';
import jkButton from '../utils/jkButton.vue';

export default {
  components: {
    Image,
    jkButton,
  },
  computed: {
    payload() {
      return this.$store.state.router.layout.footer || {};
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;

  :deep(p),
  :deep(a) {
    @apply text-sm;
  }
}
</style>
