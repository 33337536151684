<template>

  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.slug || component.data.slug"
      :key="'part-' + (component.id || component.data.id)"
      :payload="component.data || component"
      class="fade-item part"
    />
  </transition-group>

</template>

<script>
import {
  computed,
  defineAsyncComponent,
} from 'vue';

const NotFound = defineAsyncComponent(() => import('@/components/structure/NotFound.vue'));
const Footer = defineAsyncComponent(() => import('@/components/structure/Footer.vue'));
const SubpageHeader = defineAsyncComponent(() => import('@/components/structure/SubpageHeader.vue'));

const Header = defineAsyncComponent(() => import('@/components/parts/Header.vue'));
const PageIcon = defineAsyncComponent(() => import('@/components/parts/PageIcon.vue'));
const BildundMediaBlock = defineAsyncComponent(() => import('@/components/parts/BildundMediaBlock.vue'));
const SubpageGrid = defineAsyncComponent(() => import('@/components/parts/SubpageGrid.vue'));
const EinfacherTextmitTitel = defineAsyncComponent(() => import('@/components/parts/EinfacherTextmitTitel.vue'));
const EinfachesBild = defineAsyncComponent(() => import('@/components/parts/EinfachesBild.vue'));
const NewsPreview = defineAsyncComponent(() => import('@/components/parts/NewsPreview.vue'));
const EventsPreview = defineAsyncComponent(() => import('@/components/parts/EventsPreview.vue'));
const WeitereInformationen = defineAsyncComponent(() => import('@/components/parts/WeitereInformationen.vue'));
const TextmitIcon = defineAsyncComponent(() => import('@/components/parts/TextmitIcon.vue'));
const TextinAbschnitten = defineAsyncComponent(() => import('@/components/parts/TextinAbschnitten.vue'));
const TeamBlock = defineAsyncComponent(() => import('@/components/parts/TeamBlock.vue'));
const EinfacherButton = defineAsyncComponent(() => import('@/components/parts/EinfacherButton.vue'));
const ProjektGrid = defineAsyncComponent(() => import('@/components/parts/ProjektGrid.vue'));
const AufklappbareTexteGrid = defineAsyncComponent(() => import('@/components/parts/AufklappbareTexteGrid.vue'));
const HTMLBlock = defineAsyncComponent(() => import('@/components/parts/HTMLBlock.vue'));
const InstagramSlider = defineAsyncComponent(() => import('@/components/parts/Instagram-Slider.vue'));
const BilderSlider = defineAsyncComponent(() => import('@/components/parts/BilderSlider.vue'));
const Formular = defineAsyncComponent(() => import('@/components/parts/Formular.vue'));

export default {
  components: {
    NotFound,
    Footer,
    SubpageHeader,

    Header,
    BildundMediaBlock,
    SubpageGrid,
    EinfacherTextmitTitel,
    EinfachesBild,
    NewsPreview,
    EventsPreview,
    PageIcon,
    WeitereInformationen,
    TextmitIcon,
    TextinAbschnitten,
    TeamBlock,
    EinfacherButton,
    ProjektGrid,
    AufklappbareTexteGrid,
    HTMLBlock,
    InstagramSlider,
    BilderSlider,
    Formular,
  },
  props: {
    inject: { type: Object, default: Object },
    meta: { type: Object, default: Object },
  },
  setup(props) {
    const parts = computed(() => props.inject.filter((part) => !part.data?.hidden));

    return {
      parts,
    };
  },
};
</script>
