<template>
  <div class="arrow" :style="{ '--arrow-size': size }" />
</template>

<script>
export default {
  props: { size: { type: String, default: '30px' } },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

:root {
    --arrow-size: px(30);
}
.arrow {
    @apply rounded-full;
    @apply border;
    border-color: rgba(255, 255, 255, 0.6);
    width: var(--arrow-size);
    height: var(--arrow-size);
    transition: all var(--speed-slow);

    position: relative;

    &.active {
        @apply border-primary;

        &::before {
            @apply bg-primary;
        }

        &::after {
            @apply border-t;
            @apply border-r;
            @apply border-primary;
        }
    }

    &::before {
        content: "";
        background: rgba(255, 255, 255, 0.6);
        width: calc(var(--arrow-size) / 2);
        height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
    }

    &::after {
        content: "";
        @apply border-t;
        @apply border-r;
        border-color: rgba(255, 255, 255, 0.6);
        width: calc(0.29 * var(--arrow-size));
        height: calc(0.29 * var(--arrow-size));
        position: absolute;
        top: 50%;
        left: 100%;
        transform-origin: top right;
        transform: translateX(-14px) rotate(45deg);
    }
}
</style>
